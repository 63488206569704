/* storybook-check-ignore */
import { useEffect } from 'react';

import { Document } from '@contentful/rich-text-types';
import { Box } from '@opendoor/bricks-next';
import { setAppElement } from '@opendoor/bricks/complex';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import CloseIcon from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close';
import { sizes as textSizes } from '@opendoor/bricks/theme/novo/components/text';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import GlobalStyles from 'components/shared/GlobalStyles';

import { ISiteFooter, ISiteHeader } from 'declarations/contentful';

import Header from './shared/header/Header';

const ReturnExperienceBanner = dynamic(() => import('./ReturnExperienceBanner'));
const MaintenanceBanner = dynamic(() => import('../shared/MaintenanceBanner'));
const Footer = dynamic(() => import('./shared/footer/Footer'));

interface LayoutProps {
  header?: ISiteHeader;
  slug: string;
  children: React.ReactNode;
  footer: ISiteFooter | undefined;
  extraLegalText: Document | undefined;
  showEligibilityLegal: boolean | undefined;
  showCustomerTestimonialLegal: boolean | undefined;
  showThePdpCopyrightLegal: boolean | undefined;
  showPromotionLegal: boolean | undefined;
  showReturnExperienceBanner: boolean | undefined;
  setModalElement?: boolean | undefined;
}

interface IMenuLink {
  name: string;
  href: string;
}

let ctaLink: IMenuLink;

const Layout = ({
  header,
  slug,
  footer,
  extraLegalText,
  showEligibilityLegal,
  showCustomerTestimonialLegal,
  showThePdpCopyrightLegal,
  showPromotionLegal,
  showReturnExperienceBanner,
  children,
  setModalElement,
}: LayoutProps) => {
  useEffect(() => {
    if (setModalElement) {
      // setModalElement is a storybook testing prop, skipping `setAppElement` in storybook fixes rendering issues.
      setAppElement('#__next');
    }
  }, []);

  // build nav here, and check for mainNavLinks && menuPanelNavLinks
  // to show the header
  const mainNavLinks: IMenuLink[] = [];
  header?.fields?.mainNav?.forEach((link) => {
    if (link.fields.text && link.fields.url) {
      mainNavLinks.push({
        name: link.fields.text,
        href: link.fields.url,
      });
    }
  });

  const menuPanelNavLinks: IMenuLink[] = [];
  header?.fields?.menuPanelNav?.forEach((link) => {
    if (link.fields.text && link.fields.url) {
      menuPanelNavLinks.push({
        name: link.fields.text,
        href: link.fields.url,
      });
    }
  });

  const ctaTitle = header?.fields.menuPanelCtaTitle;
  if (header?.fields?.menuPanelCta?.fields.text && header?.fields?.menuPanelCta?.fields.url) {
    ctaLink = {
      name: header.fields.menuPanelCta.fields.text,
      href: header.fields.menuPanelCta.fields.url,
    };
  }

  return (
    <ThemedLite
      baseTheme="novo"
      team="growth"
      launchDateString={new Date().toISOString()}
      // NOTE: This is a hack to fix responsive sizing due to issues in the feature's figma designs
      // TODO(@product-growth): Remove this when the design system is updated
      overrides={{
        components: {
          Text: { sizes: UpdatedTextSizing },
          Heading: { sizes: UpdatedTextSizing },
        },
      }}
    >
      <Head>
        <link rel="preconnect" href="https://images.opendoor.com" />
        <link rel="dns-prefetch" href="https://images.opendoor.com" />
        {/* A Cloudflare Worker rule redirects demo?.(simplersell.opendoor).com/fonts to the actual font object, so
          do not blindly copy and paste these entries to another application— they might not magically work!
         */}
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="/fonts/graphik-regular/Graphik-Regular.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="/fonts/graphik-medium/Graphik-Medium.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="/fonts/graphik-semibold/Graphik-Semibold.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="/fonts/graphik-bold/Graphik-Bold.woff"
          as="font"
        />
      </Head>
      <GlobalStyles />
      <SelectiveSpritesheet icons={[ArrowLeft, ArrowRight, CloseIcon]} />
      <MaintenanceBanner />
      <Box position="relative" display="block">
        {showReturnExperienceBanner && <ReturnExperienceBanner />}
        {menuPanelNavLinks.length > 0 && mainNavLinks.length > 0 && (
          <Header
            menuPanelNavLinks={menuPanelNavLinks}
            mainNavLinks={mainNavLinks}
            ctaTitle={ctaTitle}
            ctaLink={ctaLink}
            slug={slug}
          />
        )}
        {/* eslint-disable @opendoor/no-html-elements */}
        <main tabIndex={-1}>
          <Box width="100%" margin={0} display="block">
            {children}
            {footer && (
              <Footer
                footerImage={footer.fields.footerImage?.fields.file.url}
                footerTitle={footer.fields.footerTitle}
                footerNav={footer.fields.footerNav}
                googlePlaystoreLink={footer.fields.googlePlaystoreLink}
                appleStoreLink={footer.fields.appleStoreLink}
                helpCenterLink={footer.fields.helpCenterLink}
                backgroundColor={footer.fields.backgroundColor}
                extraLegalText={extraLegalText}
                showCustomerTestimonialLegal={showCustomerTestimonialLegal}
                showEligibilityLegal={showEligibilityLegal}
                showPromotionLegal={showPromotionLegal}
                showThePdpCopyrightLegal={showThePdpCopyrightLegal}
              />
            )}
          </Box>
        </main>
      </Box>
      {/* eslint-enable @opendoor/no-html-elements */}
    </ThemedLite>
  );
};

/**
 * All the following typography overrides are for the 2023 Homepage Reskin project
 * https://www.figma.com/file/g2qLawddmhROv347l6EF7m/HP-Reskin?type=design&node-id=325-28241&mode=design&t=5CA6uSlmM7nuVuIC-0
 * TODO: re-assess overrides after experiment is over
 *
 * NOTE: This object gets deep merged w/ the novo theme so we only need to define the differences
 */
const UpdatedTextSizing: typeof textSizes = {
  ...textSizes,
  100: {
    fontSize: ['14px', null, null, '18px'],
    lineHeight: '140',
    letterSpacing: '-1.4',
  },
  // Used in FAQsDisplay, VideoHero, ReviewCard, MenuPanel
  110: {
    fontSize: ['16px', null, '14px', '16px'],
    lineHeight: ['25.6px', null, '25.6px', '160%'],
    letterSpacing: ['-1.4%', '-0.014em', '-0.014em', '-0.018em'],
  },
  // Used in Hero, ReviewCard
  120: {
    fontSize: '18px',
    lineHeight: '120',
    letterSpacing: '-2.4',
  },
  // Used in StickyCTAUtility
  130: {
    fontSize: ['16px', null, '19px'],
    lineHeight: '19px',
    letterSpacing: '-2.4',
  },
  // Used in Footer, Hero
  140: {
    fontSize: '22px',
    lineHeight: '112',
    letterSpacing: '-2.4',
  },
  // Used in FAQsDisplay, MapBackgroundHero, Steps, Testimonials, TextSection
  150: {
    fontSize: ['16px', null, null, '24px'],
    lineHeight: ['130%', null, null, '112%'],
    letterSpacing: ['-0.014em', null, null, '-0.028em'],
  },
  // Used in HeroCard, VideoHero
  160: {
    fontSize: ['18px', '19px', null, '28px'],
    lineHeight: ['120%', null, null, '110%'],
    letterSpacing: ['-0.024em', '-0.02em', '-0.028em', '-0.028em'],
  },
  // Used in CarouselCards, Footer
  170: {
    fontSize: ['32px', '36px', '44px', '56px'],
    lineHeight: ['35.2px', '36px', '44px', '56px'],
    letterSpacing: ['-2.8', '-3', '-3.4'],
    fontWeight: 500,
  },
  // Used in ImageCard
  180: {
    fontSize: ['16px', '18px', null, '28px'],
    lineHeight: ['120%', null, null, '110%'],
    letterSpacing: ['-0.024em', '-0.02em', '-0.028em', '-0.028em'],
  },
  // Used in HeroCard, ImageCard
  220: {
    fontSize: ['22.2px', '28px', '36px', '56px'],
    lineHeight: ['106%', '110%', '100%'],
    letterSpacing: ['-0.024em', '-0.028em', '-0.03em', '-0.034em;'],
  },
  // Used in FAQsDisplay, MapBackgroundHero, TextSection
  230: {
    fontSize: ['32px', '36px', '44px', '64px'],
    lineHeight: ['110%', '100%'],
    letterSpacing: ['-0.028em', '-0.03em', '-0.03em', '-0.034em;'],
  },
  240: {
    fontSize: ['36px', null, '80px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  250: {
    fontSize: ['44px', null, '88px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  260: {
    fontSize: ['60px', null, '96px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
};

export default Layout;
