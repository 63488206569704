import { AddContextualData } from '@opendoor/observability/slim';
import faviconPath from '@opendoor/shared-fe/build/faviconPath';
import Head from 'next/head';

import {
  fetchLandingPageV2BySlug,
  ILandingPageV2LayoutFields,
  ILandingPageV2LayoutProps,
  LandingPageV2Props,
} from 'cms';
import { IIdToLoaderData } from 'cms/loaders';

import { DEPLOY_ENV } from 'components/globals';
import Layout from 'components/landing-pages-v2/Layout';
import { PictureSourceSpec } from 'components/shared/performance/pictures';
import { usePromptGoogleOneTap } from 'components/shared/usePromptGoogleOneTap';

import { ISeoBaseFieldsFields } from 'declarations/contentful';
import { CacheSafeGetServerSideProps } from 'declarations/shared/serverSideProps';

import { HOMEPAGE_SCHEMA } from 'schema';

const getSeoBaseTags = (fields?: ISeoBaseFieldsFields) => {
  const title = fields?.title;
  const metaDescription = fields?.metaDescription;
  const canonicalUrl = fields?.canonicalUrl;

  const robots: Array<string> = [];
  // only set these when they are explicitly set to false
  // otherwise assume indexing and follow should be allowed
  if (fields?.index === false) {
    robots.push('noindex');
  }
  if (fields?.follow === false) {
    robots.push('nofollow');
  }

  return (
    <>
      <title key="title">{title ?? "Opendoor | Sell your home the minute you're ready."}</title>
      <link rel="shortcut icon" href={faviconPath({ railsEnv: DEPLOY_ENV })} type="image/png" />
      {metaDescription ? (
        <meta key="description" name="description" content={metaDescription} />
      ) : null}
      {canonicalUrl ? <link key="canonical" rel="canonical" href={canonicalUrl} /> : null}
      {robots.length > 0 ? <meta key="robots" name="robots" content={robots.join(',')} /> : null}
    </>
  );
};

// when a no-code landing page needs to preload an image for performance reasons,
// it can include a `preloadImages` field in the loader data
// this function crunches the loader data and pulls out all the preload images
// so that the top-level page can include those preload urls in the Head section
export const getPreloadsFromLoaderData = (idToLoaderData: IIdToLoaderData) => {
  return Object.values(idToLoaderData)
    .map((loaderData: any) => {
      return loaderData.preloadImages;
    })
    .filter((preload: any) => preload)
    .flat();
};

export const getLandingpageV2ServerSideProps: CacheSafeGetServerSideProps<
  LandingPageV2Props
> = async (context) => {
  if (!context.params?.slug) {
    return {
      notFound: true,
    };
  }

  let pageNum = 1;
  if (context.query.page) {
    pageNum = parseInt(context.query.page as string);
  }
  if (pageNum < 1) pageNum = 1;

  // collapse the slug from an array to a single string
  let slug = '';
  // if slug is a string and not an array
  if (typeof context.query.slug === 'string') {
    slug = context.query.slug;
  } else if (Array.isArray(context.query.slug)) {
    slug = context.query.slug.join('/');
  }

  try {
    const props = await fetchLandingPageV2BySlug({
      slug,
      requestUrl: context.resolvedUrl,
      pageNum,
    });

    if (!props) {
      return {
        notFound: true,
      };
    }

    // check if the page is set to public, and reject if appropriate
    // this is intended to block non-public pages from being directly accessed by users,
    // while still letting them be used in experiments as treatments
    if (context.query.public && props.public === false) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        ...props,
      },
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      notFound: true,
    };
  }
};

const LandingPageV2Layout = <T extends ILandingPageV2LayoutFields>(
  props: ILandingPageV2LayoutProps<T>,
) => {
  usePromptGoogleOneTap(`landing-pages-v2-${props.fields.slug}`, undefined, props.hideGoogleOneTap);

  const contextualData = { landingPageSlug: props.fields.slug };

  const imagePreloads = getPreloadsFromLoaderData(props.idToLoaderData);

  return (
    <AddContextualData data={contextualData}>
      <Head>
        {getSeoBaseTags(props.fields.seoBase?.fields)}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(HOMEPAGE_SCHEMA) }}
        />
        {(imagePreloads as PictureSourceSpec).map((h) => (
          <link
            key={h.key}
            rel="preload"
            as="image"
            media={h.media}
            // @ts-expect-error: react incorrectly capitalizes this if you use
            // the "typescript" suggestion of `imgSrcSet`
            imagesrcset={h.srcSet}
            fetchpriority="high"
          />
        ))}
      </Head>
      {/* slash is to match against nav links for detecting current page */}
      <Layout
        header={props.fields?.header}
        slug={`/${props.fields?.slug}`}
        footer={props.fields?.footer}
        extraLegalText={props.fields?.extraLegalText}
        showEligibilityLegal={props.fields?.showEligibilityLegal}
        showCustomerTestimonialLegal={props.fields?.showCustomerTestimonialLegal}
        showThePdpCopyrightLegal={props.fields?.showThePdpCopyrightLegal}
        showPromotionLegal={props.fields?.showPromotionLegal}
        showReturnExperienceBanner={props.fields?.showReturnExperienceBanner}
      >
        {props.children}
      </Layout>
    </AddContextualData>
  );
};

export default LandingPageV2Layout;
